$colorPrimary: #28374c;
$colorInput: #e6e9ea;
$colorTextPrimary: #304855;
$colorBlack: #333333;
$colorSmallText: #8e9091;

.text {
  font: normal normal 600 17px/25.5px Nunito;
  color: $colorBlack;
  text-align: left;
}

.large-text {
  font: normal normal 400 2.5rem/3rem Ubuntu;
  color: $colorBlack;
}
.info {
  color: #89888a;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 0px !important;
  margin-bottom: 0;
  line-height: 22.5px;
}
.paddinglr{
  // padding: 40px 9rem !important;
  padding: 40px 3.5rem !important;

}

@media (max-width: 767px){
  .paddinglr{
    padding: 15px 15px !important;
  }
}

.taxes{
  color: #666666;
  font: normal normal 500 15px/20px  Nunito;
}
