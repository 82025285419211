@import "assets/css/text.scss";

//variables
$colorPrimary: #28374c;
$colorInput: #e6e9ea;
$colorTextPrimary: #304855;
$colorBlack: #333333;
$colorSmallText: #8e9091;
$colorDarkBlue: #212529;
$colorDarkBlack: #666666;


html {
  scroll-behavior: smooth;
}

body {
  font-family: "Nunito", sans-serif !important;
  line-height: 21px !important;
  // overflow-x: hidden
}

:root {
  font-size: 16px;
}

.section {
  padding-top: 1.5rem;
}

.bg-blue {
  background-color: #28384b;
}

@media only screen and (min-width: 350px) and (max-width: 768px) {
  :root {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 350px) {
  :root {
    font-size: 12px !important;
  }
}

* {
  outline: transparent !important;
}

a {
  text-decoration: none !important;
}

.hr2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 0;
  margin-right: 0;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 8px;
  margin-right: 8px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
  border: 2px solid $colorPrimary !important;
  background-color: $colorPrimary !important;
  box-shadow: none !important;
}

.custom-checkbox .custom-control-label {
  line-height: 26px !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  border: 2px solid #bbc6c8 !important;
  background-color: transparent !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23855' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
}

//multi select control
.select-control .css-yk16xz-control,
.select-control .css-1pahdxg-control,
.select-control .css-1fhf3k1-control {
  background-color: hsl(195deg, 9%, 91%) !important;
  height: 48px !important;
  overflow-y: auto;
  border-radius: 0;
  border-color: transparent !important;
  box-shadow: none;
}

.css-1rhbuit-multiValue {
  background-color: hsl(0deg, 0%, 80%) !important;
}

.form-control {
  background-color: $colorInput !important;
  padding: 0.7rem 0.75rem !important;
  border-radius: 0 !important;
  height: auto !important;
  border-color: transparent !important;
  background-clip: border-box !important;

  &:focus {
    box-shadow: none !important;
    color: #495057 !important;
    background-color: #fff !important;
    border: 1px solid $colorInput !important;
  }

  &.invalid {
    background-color: #fac8c8 !important;
    border: 1px solid #fac8c8 !important;
  }
}

::placeholder {
  font: normal normal normal 1rem/1.38rem Nunito !important;
  color: $colorSmallText !important;
  text-transform: capitalize;
}

select {
  width: 100%;
  padding: 0.7rem 0.65rem !important;
  border: 0 !important;
  background-color: $colorInput !important;
  font: normal normal normal 1rem/1.38rem Nunito !important;
  color: $colorBlack !important;
  border-radius: 0 !important;
  border-color: transparent !important;
}

.overflowText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.passwordEye {
  position: absolute;
  top: 2.81rem;
  right: 1.25rem;
  color: #666;
}

label {
  font: normal normal bold 14px/22px Nunito;
}

button {
  background-color: $colorPrimary;
  border: none;
  padding: 8px 10px;
  cursor: pointer;
  text-align: right;
  font: normal normal 600 14px/22.5px Nunito;
  color: #ffffff;
  text-transform: capitalize;

  @media only screen and (min-width: 300px) and (max-width: 320px) {
    padding: 0.75rem 1.19rem;
  }

  img {
    margin-left: 2px;
    margin-right: 2px;
  }
}

.bordered-btn {
  font: normal normal 1.25rem/1.68rem Nunito !important;
  color: #28374c !important;
  padding: 0.63rem 0.75rem 0.63rem 0.75rem !important;
  border: 2px solid #28374c !important;
  border-radius: 0 !important;
  max-width: 100%;

  &:hover:not(:disabled) {
    background-color: #28374c !important;
    transition: 0.5s all;
    color: #fff !important;
  }
}

.white-bordered-btn {
  border: 2px solid #faf3e1;
  font: normal normal 500 17px/27px Nunito;
  color: #faf3e1;
  padding: 10px;
}

button:disabled,
input[type="button"]:disabled {
  cursor: not-allowed;
}

input[type="text"]:disabled,
input[type="email"]:disabled,
select:disabled {
  cursor: not-allowed;
  background-color: #d1cdcd !important;
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-checkbox .custom-control-label::before,
.custom-checkbox .custom-control-label::after {
  border: 2px solid #bbc6c8;
  border-radius: 2px !important;
  height: 1.2rem;
  width: 1.2rem;
}

.bg-grey {
  background-color: #f6f6f6 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.AddAddressModalBody {
  .nav-link {
    font: normal normal 600 1.25rem/1.68rem Nunito;
    color: #333333;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #304855;
    background-color: transparent !important;
    border-radius: 0 !important;
    border-bottom: 2px solid $colorPrimary !important;
  }

  ::-webkit-scrollbar {
    width: 0px !important;
  }

  .custom-control-inline:first-child {
    margin-left: 4px;
  }
}

#rate_us .selected {
  color: #ffc014 !important;
}

.star_wrap .glyphicon {
  padding: 0 3px;
  color: #aaa;
  font-size: 1rem;
}

//check and Remove from cart items page
.input-group {
  margin: 10px 0;
  font: normal normal 600 0.88rem/1.25rem Nunito;
  color: $colorTextPrimary;
  width: 100%;
}

.input-group input[type="button"] {
  background-color: transparent;
  min-width: 2.38rem;
  width: auto;
  transition: all 300ms ease;
  border: 2px solid $colorTextPrimary;
  font-size: 1.5rem;
  font-weight: 500;
  color: $colorTextPrimary;
}

.input-group .button-minus,
.input-group .button-plus {
  font-weight: bold;
  height: 2.38rem;
  width: 2.38rem;
}

.input-group .quantity-field {
  height: 2.38rem;
  text-align: center;
  width: 4.38rem;
  font: normal normal 600 1.2rem/1rem Nunito;
  color: $colorTextPrimary;
  margin-right: 10px;
  border: 2px solid transparent;
  border-radius: 0;
  margin-left: 10px;
}

///SLICK SLIDERS CSS////

.homeSlider1 {
  .slick-arrow {
    color: $colorPrimary !important;
    background: #ebecec !important;
    height: 110px;
    width: 25px;
    z-index: 2;
    opacity: 1 !important;
  }
}

.slick-prev,
.slick-next {
  top: 50% !important;
}

.slick-prev:before {
  background: #ebecec !important;
  content: "\f053" !important;
  font-weight: 600;
  font-family: "Font Awesome 5 Free" !important;
  padding-right: 6px;
  text-align: center !important;
  font-size: 20px !important;
  color: $colorPrimary !important;
}

.slick-next:before {
  background: #ebecec !important;
  content: "\f054" !important;
  font-weight: 600;
  font-family: "Font Awesome 5 Free" !important;
  text-align: center !important;
  font-size: 20px !important;
  color: $colorPrimary !important;
  padding-right: 4px;
}

.slick-prev {
  left: -0.9rem !important;
}

.slick-next {
  right: -0.9rem !important;
}

.bottom-center {
  bottom: 1em;
  left: -50%;
  transition: transform 0.1s ease-in;
  animation: toast-in-center 0.2s;
}

@keyframes toast-in-center {
  from {
    transform: translateY(30%);
  }

  to {
    transform: translateY(0);
  }
}


// home page diwali popup
.diwali-pop-up {
  background-color: #000000a8;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  .modal-dialog {
    max-width: 700px;
  }

  .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: 0;
  }
}

.location-pop-up {
  .modal-dialog {
    height: 90vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-body {
    max-height: 85vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 5px 10px 0 0 !important;

    &::-webkit-scrollbar {
      width: 0px !important;
    }
  }
}

.address-pop-up {
  .modal-dialog {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 60vw !important;
    margin: 1rem auto;

    @media (max-width: 768px) {
      max-width: 90vw !important;
    }

    .add-new-address {
      text-align: left;
      font: normal normal 600 24px/37px Ubuntu;
      color: $colorTextPrimary;
      text-transform: capitalize;
    }

    .pane-content {
      padding-top: 10px;
      padding-bottom: 10px;
      max-height: 70vh;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .account-heading {
      font: normal normal 600 20px/27px Nunito;
      color: $colorBlack;
      opacity: 1;
      // border-bottom: 1px solid ${color.colorBoxShadow};
      // padding-bottom: 10px;
      // margin-top: 20px;
    }

    .nav-pills .nav-link {
      padding: 14px 16px;
    }
  }
}

.order-popup {
  .modal-dialog {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50vw !important;

    @media only screen and(max-width: 768px) {
      max-width: 95vw !important;
      margin: auto;
    }
  }

  .modalheading {
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #bbc6c8;
  }

  .modal-body {
    padding: 0 !important;

    p {
      margin-bottom: 0.3rem;
    }

    .close {
      position: relative;
      top: -10px;
    }

    h2 {
      font: normal normal 500 2.19rem/2.88rem Ubuntu;
      color: $colorPrimary;
    }
  }

  .modal-content {
    border-radius: 0 !important;
  }

  .modalContent {
    h5 {
      font: normal normal normal 1.19rem/2.88rem Ubuntu;
      color: $colorPrimary;
      margin-bottom: 0;
    }
  }
}

.video-popup {
  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    max-width: 70vw !important;
  }

  .modal-body {
    padding: 0 !important;

    .close {
      cursor: pointer;
      border-radius: 50%;
      color: #fff;
      opacity: 1;
    }
  }

  .modal-content {
    border: none;
    background-color: transparent;
    border-radius: 0 !important;
  }
}

.data-table-extensions {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;

  .filter-text {
    border-bottom: 1px solid $colorPrimary !important;
    color: $colorPrimary !important;
  }
}

.resetBtn {
  background-color: #efefef;
  border: 2px solid transparent !important;
  padding: 10px;
  cursor: pointer;
  font: normal normal 600 14px/19px Nunito !important;
  color: $colorPrimary;
  text-transform: capitalize !important;
}

.bordered-btn-1 {
  font: normal normal 600 14px/19px Nunito !important;
  color: #28374c;
  padding: 10px !important;
  border: 2px solid #28374c !important;
  border-radius: 0 !important;

  &:hover:not(:disabled) {
    background-color: #28374c !important;
    transition: 0.5s all;
    color: #fff !important;
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}

// Change Address Popup in Checkout Page
.change-address-modal {
  .modal-dialog {
    height: 510px;
    display: flex;
    justify-content: center;


    .modal-body {
      overflow: auto;
    }

    max-width: 35vw !important;
    margin: 1rem auto;

    @media (max-width: 768px) {
      max-width: 90vw !important;
    }

    .add-new-address {
      text-align: left;
      font: normal normal 600 24px/37px Ubuntu;
      color: $colorTextPrimary;
      text-transform: capitalize;
      margin-bottom: 0;
    }

    .address-details {
      border: 2px solid #BBC6C8;
      border-radius: 4px;
      padding: 10px;
      padding-bottom: 0;
      height: 175px;
      overflow: auto;
      margin-bottom: 25px;
      margin-top: 15px;

      h6 {
        font: normal normal 700 16px/22px Nunito;
        color: #333333;
      }

      p {
        margin-bottom: 10px;
      }
    }
  }
}

.payment-details {
  .modal-dialog {
    height: auto;
    display: flex;
    justify-content: center;

    .modal-body {
      overflow: auto;
    }

    max-width: 40vw !important;
    margin: 1rem auto;

    @media (max-width: 768px) {
      max-width: 90vw !important;
    }

    @media only screen and (device-width: 1280px) and (device-height: 720px) {
      max-width: 75vw !important;
    }

    .add-new-address {
      text-align: left;
      font: normal normal 600 24px/37px Ubuntu;
      color: $colorTextPrimary;
      text-transform: capitalize;
      margin-bottom: 0;
    }

    .payment-modalbody {
      padding: 20px 0 0 0;

      h4 {
        font: normal normal 200 18px/0 Ubuntu;
        color: #222;
        padding-top: 15px;
        padding-bottom: 20px;
        margin-bottom: 0;
      }

      .payment-details {
        padding: 10px 0px !important;
        // background: #f4f4f4;
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: 10px;
        line-height: 30px;
        width: 100%;

        td {
          font: normal normal 600 16px/25px Nunito;
          color: #222;
        }
      }

      p {
        font: normal normal 600 16px/25px Nunito;
        color: #222;
      }

      .payment-status {
        margin: 20px 0;
      }

      .payment-status {
        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0 5px;

        }

        label {
          font: normal normal normal 16px/22px Nunito;
        }

        button {
          padding: 4px 5px;
          font-size: 14px;
          cursor: pointer;
          width: fit-content;
          text-align: center;
          background-color: #28384b;

        }

        tr {
          vertical-align: initial;
        }

        .credit-days {
          font: normal normal normal 15px/25px Nunito;
          margin-bottom: 0;
        }

        span {
          font: normal normal 700 16px Nunito;
          color: #333333;

        }
      }
    }
  }
}

h4.location a {
  color: #fff !important;
}

.title_style {
  font: 500 16px / 36px Ubuntu !important;
  margin-top: 20px;
}

.notification-ui_dd-content .margin-left-0 {
  margin-left: 0 !important;
}

.notification-ui_dd-content .margin-left-0 h3 {
  font: 500 22px / 36px Ubuntu !important;
}

.whatsapp-icon-div a {
  background-color: #39c120;
  padding: 10px;
  width: max-content;
  border-radius: 10px;
  margin-right: 15px;
  font-size: 20px;

  i {
    color: #fff;
    margin-right: 7px;
  }

  span {
    color: #fff;
  }

  img {
    width: 25%;
  }
}

.description-table {
  white-space: nowrap;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disableDiv {
  pointer-events: none;
  opacity: .4;
}

.c-red {
  color: red !important;
}

.selected-now-address {
  margin-bottom: 25px !important;
  border: 2px solid #BBC6C8;
  border-radius: 4px;
  padding: 10px;
  padding-bottom: 0;

  h6 {
    margin-bottom: 10px !important;
    font-size: 18px !important;
  }

  p {
    margin-bottom: 6px !important;
    font-size: 14px !important;
  }
}

.selectRfq {
  padding-bottom: 20px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.font-small {
  font: normal normal 400 14px Ubuntu;
}

.graphs {
  text-align: center;
  padding-bottom: 20px;

  button {
    margin: 10px;
  }

}

.rdrDefinedRangesWrapper {
  background: #f6f6f6;

  .rdrStaticRangeLabel {
    padding: 10px 10px;
  }

  .rdrStaticRange {
    background: #f6f6f6;
    color: #333;
  }

  .rdrInputRanges {
    display: none;
  }
}

.recharts-legend-wrapper {
  position: absolute;
  top: 10rem !important;
  right: -15rem !important;

  .recharts-default-legend {
    display: flex;
    flex-direction: column;
    text-align: left !important;
    font-size: 14px;
  }
}

#menu2 {
  .recharts-wrapper {
    height: 424px !important;

    .recharts-surface {
      overflow: visible !important;
    }

  }




}

.text-blue{
  color: #28384b !important;
}