.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 490px;
  top: 33px;
  transform: translate(0, 10px);
  background-color: #28384b;
  padding: 5px 15px 15px 15px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  min-width: auto;
  border-radius: 5px;
}

.popover__content span {
  padding-bottom: 2px;
}

.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  left: -20px;
  top: 7px;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent #28384b transparent;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message {
  text-align: center;
}

a .fas.fa-file-pdf {
  color: #fff;
}

.warranty-months {
  font: normal normal 600 14px/16px Nunito;
  color: #fff;
  display: flex;
  justify-content: center;
}

.description {
  padding: 30px 0 0px 0;
}

.description h4,
.specification-div h4 {
  font: normal normal 600 1.15rem/1.69rem Nunito;
  color: #333;
}

.specification {
  padding: 0px 0 30px 0;
}


.description p {
  padding-right: 10px;
  padding-top: 0;
  font: normal normal 400 16px/25.5px Nunito;
  color: #333333;
  text-align: justify;

}

.select-dropdown {
  color: #28374C !important;
  border: 2px solid #28374C !important;
  font: normal normal 600 15px/0 Nunito !important;
  height: 45px !important;
  width: 100%;
}

.select-dropdown:focus {
  border: 2px solid #28374C !important;
}

.product-c-m-b h4 {
  font: normal normal 600 1.4rem/32px Nunito;
  color: #333;
  margin-bottom: 5px;
}

.product-c-m-b p {
  font: normal normal 600 1.2rem/28px Nunito;
  color: #333;
  padding-bottom: 25px;
  margin-bottom: 0;
}

.color {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  margin-right: 5px;
}

.bg-black {
  color: #000;
}

.taxes {
  color: #666666;
  font: normal normal 400 15px/20px Nunito;
}

.description-height {
  height: 55px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 15px;
}

.description-height::-webkit-scrollbar {
  width: .3em;
  background-color: #d8d8d8;
  border-radius: 20px;

  
}

.description-height::-webkit-scrollbar-track {
  box-shadow: none;
}

.description-height::-webkit-scrollbar-thumb {
  background-color: #28384b;
  outline: 1px solid #28384b;
  border-radius: 20px;
  width: .3em;
}

/* Resposnive Mobile */
@media (max-width: 600px){
  .popover__content{
    left: 21rem;
    top: 87px;
  }

  .popover__content:before{
    left: 20%;
    top: -20px;
    transform: rotate(0deg);
  }

  .delivery-button{
    display: flex !important;
    flex-basis: fit-content;
    flex-wrap: nowrap;
    align-items: center;
  }

  .color-div, .material-div, .brand-name-div{
    padding-top: 20px !important;
  }

  .description h4, .specification-div h4{
    font: normal normal 600 1.4rem/1.69rem Nunito;
  }

  .description {
    padding-right: 0 !important;
  }

  .description p{
    font: normal normal 400 15px/25.5px Nunito;
    margin-bottom: 20px;
  }

  .max-height{
    height: 300px !important; 
  }

  .main{
    height: 310px !important; 
  }

  .thumbnail img{
    width: 100px !important;
  }

  .mob-m-1rem{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mob-mb0{
    margin-bottom: 0 !important;
  }

  .mob-pb0{
    padding-bottom: 0 !important;
  }

  .input-range__slider-container:nth-last-child(1) .input-range__label-container {
    left: -15px !important;
}
.input-range__label--value {
  left:-40px;
}
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .popover__content{
      left: 18.5rem;
      top: 105px;
  }

  .popover__content:before{
    left: 20%;
    top: -20px;
    transform: rotate(0deg);
  
}
  }

/* Responsive 1280x720 */
@media only screen and (device-width: 1280px) and (device-height: 720px) {
  .description-height {
    margin-bottom: 25px;
    height: 55px;
  }
}
.color-div, .material-div, .brand-name-div{
  padding-top: 29px;
}

/* Filter */
.css-i35pig-Slider .rheostat-progress {
  background-color: #bbc6c8 !important;
}

.css-i35pig-Slider .rheostat-horizontal{
  margin: 24px 10px !important;
}

.css-go4ryr-Input-input {
  border: 1px solid #bbc6c8 !important;
  background-color: transparent !important;
  font-size: 1rem !important;
  outline: none;
  border-radius: 5px !important;
}

.css-i35pig-Slider .rheostat-handle {
  border: 3px solid #fff !important;
  background-color: #28384b !important;
  border-radius: 50%;
  height: 24px !important;
  outline: none;
  z-index: 2;
  width: 20px !important;
  cursor: pointer;
  box-shadow: 0 0 5px #28384b !important;
}
.css-ln922y-Flex{
  padding-top: 15px;
}


.input-range__label--max .input-range__label-container {
  left: 25% !important;
  font: normal normal 400 15px/25.5px Nunito !important;
  color: #28384b !important;

}

.input-range__label-container {
  left: -73% !important;
  position: relative;
  font: normal normal 400 15px/25.5px Nunito !important;
  color: #28384b !important;
}

.input-range__slider{
  background-color: #28384b !important;
  border:2px solid #fff !important;
  box-shadow: 0 0 5px #28384b;
}

.input-range__track--active {
  background: #bbc6c8 !important;
}
.input-range__label--value{
  top: -2.3rem !important;
}
.input-range__label--min, .input-range__label--max{
  bottom: -2rem !important;
}

.input-range__track{
  background-color: #bbc6c8 !important;
}
/* Ends here */

.react-transform-wrapper{
  margin: 0 auto !important;
  width: 100%;
}

.input-range__slider-container:nth-last-child(1) .input-range__label-container{
  top: 46px;
  position: relative;
}

.input-range__label--max .input-range__label-container, .input-range__label--min .input-range__label-container{
  display: none;
}

/* Tooltips product page */
.textonhover{
  background: #292B2C;
  padding: 1px 5px;
  position: absolute;
  color: #fff;
  font-size: 12px;
  width: auto;
  z-index: 9;
}